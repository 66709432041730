import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Images from "../Images/Images"
import AppContext from '../Context/AppContext';
import navigation from '../../utilities/HeaderItems';

function Header() {
    const { sidebarLayout, setSidebarLayout } = useContext(AppContext)
    // const [isAffix, setIsAffix] = useState(false);
    // const [affixThreshold, setAffixThreshold] = useState(100);
    const [activeLabel, setActiveLabel] = useState(null);
    const [activeChildIndex, setActiveChildIndex] = useState(null);
    const [activeChildIndices, setActiveChildIndices] = useState({});
    const location = useLocation();
    const [scrollY, setScrollY] = useState(0);
    const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 768);


    useEffect(() => {
        const handleScroll = () => {
            // Check if the window width is above 768 pixels
            if (window.innerWidth > 768) {
                setScrollY(window.scrollY);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    // useEffect(() => {

    //     const handleScroll = (currentLocation) => {
    //         const scrollPosition = window.scrollY;
    //         var height = window.innerHeight;


    //         if (height > 1000) {
    //             setAffixThreshold(400)
    //         } else {
    //             setAffixThreshold(100)
    //         }

    //         // Check if the scroll position is below the threshold
    //         if (scrollPosition > affixThreshold) {
    //             setIsAffix(true);
    //         } else {
    //             setIsAffix(false);
    //         }
    //     };

    //     // Attach the scroll event listener
    //     window.addEventListener('scroll', handleScroll);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [affixThreshold]);

    useEffect(() => {
        // Update active link when location.pathname changes
        const currentNavItem = navigation.find((item) =>
            item.exact ? location.pathname === '/' : location.pathname.startsWith(item.url)
        );

        // if (currentNavItem) {
        //     setActiveLabel(currentNavItem.label);
        //     // setActiveChildIndex(currentNavItem.children);
        //     setActiveChildIndex(currentNavItem.children ? 0 : null);
        // }

        if (currentNavItem) {
            setActiveLabel(currentNavItem.label);

            // Find the index of the active child based on the current URL
            const activeChildIndex = currentNavItem.children
                ? currentNavItem.children.findIndex((child) => location.pathname.startsWith(child.url))
                : null;

            setActiveChildIndex(activeChildIndex >= 0 ? activeChildIndex : null);
        }
    }, [location.pathname]);

    const handleChildItemClick = (label, childIndex, hasChildren) => {
        setActiveChildIndex(childIndex);
        setActiveLabel(label);

    };

    return (

        <div className={`header-main header-wrapper sticky-header`}>
            {/* ${isAffix ? 'affix' : 'affix-top'}`}
            data-spy="affix" data-offset-top="200" > */}

            <div className={`toolbar ${scrollY > 100 ? 'mb-0' : ''}`}>
                <div className='container'>
                    <div className='row'>
                        <div className='toolbar-wrapper'>
                            <div className='col-md-12 col-sm-12'>
                                <div>
                                    <span className={`sticky-logo ${scrollY > 100 ? 'sticky-logo-display' : ''}`}>
                                        <div className='logo'>
                                            <Link to="/" title="ENEXIO">
                                                <img src={Images.EnexioLogo} alt="ENEXIO" class="logo img-responsive" width="120px" />
                                            </Link>
                                        </div>
                                    </span>
                                </div>
                                <div>
                                    <div id='searchbox'>
                                        <form action='/search-results/' method='post' className='form-horizontal' id='tx_indexedsearch2' role='form'>
                                            <div>
                                                <input type="hidden" name="tx_indexedsearch[_sections]" value="0" />
                                                <input type="hidden" name="tx_indexedsearch[_freeIndexUid]" id="tx_indexedsearch_freeIndexUid2" value="_" />
                                                <input type="hidden" name="tx_indexedsearch[pointer]" id="tx_indexedsearch_pointer2" value="0" />
                                                <input type="hidden" name="tx_indexedsearch[ext]" value="" />
                                                <input type="hidden" name="tx_indexedsearch[type]" value="1" />
                                                <input type="hidden" name="tx_indexedsearch[defOp]" value="0" />
                                                <input type="hidden" name="tx_indexedsearch[media]" value="-1" />
                                                <input type="hidden" name="tx_indexedsearch[order]" value="rank_flag" />
                                                <input type="hidden" name="tx_indexedsearch[group]" value="flat" />
                                                <input type="hidden" name="tx_indexedsearch[desc]" value="0" />
                                                <input type="hidden" name="tx_indexedsearch[results]" value="10" />
                                            </div>
                                            <fieldset>
                                                <div class="tx-indexedsearch-form input-group">
                                                    <input type="text" name="tx_indexedsearch[sword]" value="" id="tx-indexedsearch-searchbox-sword2" class="tx-indexedsearch-searchbox-sword-long sword input-md form-control" placeholder="SEARCH" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search'" />
                                                    <span class="input-group-btn">
                                                        <input type="submit" name="tx_indexedsearch[submit_button]" value=" " id="tx-indexedsearch-searchbox-button-submit2" class="tx-indexedsearch-searchbox-button submit btn btn-primary" />
                                                    </span>
                                                    <i id="search-trigger" class="fa fa-search" aria-hidden="true"></i>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header className='top'>
                <div className="container" >
                    <div className='head-image-section  logo-bar' >
                        <div className='headerLogo'>
                            <Link to='/'> <img src={Images.EnexioLogo} alt='Logo' className={scrollY > 100 ? 'LogoScrollWidth my-2 ' : ''} /></Link>
                        </div>
                        <div className='pull-right claim'>
                            <img src={Images.textimg} alt='Claim' className={scrollY > 100 ? 'displayNone' : ''} />
                        </div>
                    </div>
                    <div className={`header-menu ${scrollY > 100 ? 'Lessmargin' : ''}`} >

                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <nav className="navbar" role="navigation">

                                <div className=" navbar-collapse" id="mainnavbar" aria-expanded="true">
                                    <ul className="navbar-list nav navbar-nav">
                                        {navigation.map((item, itemIndex) => (
                                            <>
                                                <li key={itemIndex} className={`dropdown hovernav active current ${(item.exact && location.pathname === '/') ||
                                                    activeLabel === item.label
                                                    ? 'active-link'
                                                    : ''
                                                    }`}>


                                                    {/* // <li key={itemIndex} className={`dropdown hovernav active current ${activeLabel === item.label ? 'active-link' : ''}`}> */}
                                                    {
                                                        item.children ? (<Link to={item.url} className="dropdown-toggle" role="button" data-toggle="dropdown" data-target="#" onClick={() => setActiveLabel(item.label)}>
                                                            {item.label}
                                                        </Link>) : (
                                                            <Link
                                                                to={item.url}
                                                                className={`dropdown-toggle after-dropdown ${activeLabel === item.label ? 'active-link' : ''
                                                                    }`}
                                                                role="button"
                                                                data-toggle="dropdown"
                                                                data-target="#"
                                                                onClick={() => setActiveLabel(item.label)}
                                                            >
                                                                {item.label}
                                                            </Link>)
                                                    }
                                                    {
                                                        item.children ? (
                                                            < ul className="dropdown-menu" role="menu">
                                                                {item.children.map((child, childIndex) => (



                                                                    <li
                                                                        key={childIndex}
                                                                        className={`active current ${activeChildIndex === childIndex && activeLabel === item.label
                                                                            ? 'active-link'
                                                                            : ''
                                                                            }`}
                                                                    >
                                                                        <Link
                                                                            to={child.url}
                                                                            onClick={() =>
                                                                                handleChildItemClick(item.label, childIndex, true)
                                                                            }
                                                                        >
                                                                            {child.label}
                                                                        </Link>
                                                                    </li>

                                                                ))

                                                                }
                                                            </ul>
                                                        ) : (<></>)

                                                    }
                                                </li>
                                            </>
                                        ))

                                        }
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div >
            </header >

        </div >
    )
}

export default Header