import React from 'react'
import Images from '../Images/Images'

function Error404() {
    return (
        <div className='error-main'>

            <div className='heading-text'>

                <img src={Images.Error404} />

                <div className='sub-head col-lg-6'>
                    <img src={Images.Construction} />

                    <div>

                        <h1>Oops! <span className='ice-blue'>WE DID NOT FIND
                            THIS PAGE OR FILE!</span> </h1>
                        <p className='lead'>Contact
                            us directly via<a href='mailto:'> E-Mail.</a></p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Error404