import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import PagesCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"



const ClassicAirCooledCondenser = () => {
    const pageImages = [


        {
            id: 1,
            image: Images.Classic,
            title: "ENEXIO Classic ACC for 775 MW CCPP"
        },
        {
            id: 2,
            image: Images.Classic2,
            title: "ENEXIO Classic ACC for 45 MW Waste-to-energy Power Plant"
        },
        {
            id: 3,
            image: Images.Classic3,
            title: "ENEXIO Classic ACC for 413 MW CCPP"
        },
        {
            id: 4,
            image: Images.Classic4,
            title: "ENEXIO Classic ACC for 1 x 250 MW CCPP - Altomonte, Italy in the year 2004"
        },
        {
            id: 5,
            image: Images.Classic5,
            title: "ENEXIO Classic ACC for 1 x 750 MW Coal Fired Power Plant - Kogan Greek, Australia in the year 2006"
        },
        {
            id: 6,
            image: Images.Classic6,
            title: "ENEXIO Classic ACC for 805 MW CCPP"
        },
        {
            id: 7,
            image: Images.Classic7,
            title: "ENEXIO Classic ACC for 2 x 750 MW CCPP - Erbil, Iraq in the year 2013"
        },
        {
            id: 8,
            image: Images.Classic8,
            title: "ENEXIO Classic ACC for 6 x 740 MW Coal Fired Power Plant -  Eskoms Medupi Power Station, South Africa in the year 2008"
        },
        {
            id: 9,
            image: Images.Classic9,
            title: "ENEXIO Classic ACC for 6 x 665 MW Power Plant- Eskoms Matimba Power Station,South Africa in the year 1991"
        },
       
    ]
    return (
        <div className='classicaircooled-main'>
            <div className='heading-text mb-3'>
                <h1>CLASSIC AIR COOLED CONDENSERS (ACC)</h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>ENEXIO DEVELOPS SUSTAINABLE COOLING SYSTEMS TO PRESERVE NATURAL RESOURCES</h2>
            </div>
            <div id="c1437" className='headtext-section'>
                <p className="lead">A proud part of our heritage of innovation in cooling technology is in being the inventor of the Air Cooled Condenser (ACC). The ENEXIO Air Cooled Condenser is a direct dry cooling system wherein steam is condensed inside fin tubes. </p>
                {/* <p>
                    The ENEXIO ACC is a direct dry cooling system wherein low pressure steam coming from turbine is condensed with the help of the extended ﬁn tubes. Ambient air is forced across the tube bundles for condensing the hot steam within the bundles. The result of extracting the latent heat and sensible heat from the steam is by rising of the air temperature. Due to which the steam gets condensed inside the ﬁnned tube bundle. Condensed water is collected in the condensate tank. Steam left uncondensed in condenser bundles is captured and condensed in the dephlegmator bundles.
                </p>
                <p>To maximize the customer beneﬁts with state-of-art Technology, ENEXIO is proud to use its PATENTED self- supporting ALEX ﬁnned tube bundles - in this traditional air-cooled condenser. </p>
                <p>Water is not needed for the power plant's main cooling because it is air cooled. This application addresses the issue of power plants being situated in water-scarce areas or in locations where getting water access license is challenging and time-consuming. </p> */}
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-5'>
                        <img src={Images.classicAcc_pageImg} alt="" width="100%" />
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div id="c1437" className='headtext-section mb-5'>
                        <p>Typically, the aluminum-plated fin tubes are single-row condensing tubes that are brazed with aluminum fins and are air cooled. Since they are air cooled, no water is required for main cooling of the power plant. </p>
                    </div>
                </div>
            </div>

            {/* <div id="c1438" className='heading-text mb-5'>
                <span className='lead'>BENEFITS AT A GLANCE </span>
                <ul>
                    <li>
                        Less steel structure weight
                    </li>
                    <li>
                        Patented inclined exhaust duct routing saves duct material and erection time.
                    </li>
                    <li>
                        Significant reduction of erection cost and time
                    </li>
                    <li>
                        Lower Air inlet Height & Less plot area required
                    </li>
                    <li>
                        Very high operating stability during all load transients
                    </li>
                    <li>
                        Low power consumption
                    </li>
                    <li>High performance with low sub cooling </li>
                    <li>100% effective finned surface, while minimizing </li>
                </ul>

            </div> */}
            <div className='heading-text'>
                <h3>REFERENCES AROUND THE GLOBE</h3>
                <PagesCarousel pageImages={pageImages} />
            </div>
        </div>



    )


}

export default ClassicAirCooledCondenser