import React from 'react'
import { Link } from 'react-router-dom'

function ComingSoon() {
    return (
        <div className='comingsoon-main'>
            <div className='heading-text'>
                <h1>COMING <span className='ice-blue'>SOON</span></h1>
                <h2>WE ARE STILL WORKING ON IT</h2>

                <p className='lead'>Go Back to <Link to={'/'}>Home</Link></p>
            </div>

        </div>
    )
}

export default ComingSoon