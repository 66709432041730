import React from 'react'
import { Link } from 'react-router-dom'
import "../../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../../commonComponents/Images/Images'


const SprayNozzles = () => {
    const image2 = Images.NF;
    const image4 = Images.Drift;
    const image6 = Images.FilmFill;
    const image8 = Images.SprayNozzle2;


    return (
        <div className='cooling-solutions ctcomponents'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>SPRAY NOZZLES</h1>
                </div>
                {/* <div className='sub-heading'>
                    <h2 className=''>COOLING TOWER COMPONENTS</h2>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>COMPONENTS AND FILLS FOR EVAPORATIVE COOLING TOWERS</h2>
                </div>
                <div className='headtext-section'>
                    <p className="lead">Evaporative cooling towers are still considered the most efficient way of cooling process water at industrial sites all over the world. With economic and ecological factors always an important consideration, construction and operation of wet cooling towers necessitates the use of efficient fills and drift eliminators. As the pioneer of plastic components for cooling tower applications we help our customers to meet their requirements.</p>
                </div> */}

              
                <div className='mb-4'>
                    {/* <div className='sub-heading'>
                        <h2 className='ice-blue'>SPRAY NOZZLES</h2>
                    </div> */}
                    <div className='text-center mb-3'>
                        {/* <img src={image8} alt="Rcc2" width="100%" /> */}
                    </div>
                    <div id="c1438" className='heading-text corporate-governance'>
                        <ul>
                            <li>
                                Three layers downward spray nozzle ensures uniform distribution over the entire fill area.
                            </li>
                            <li>
                                These nozzles are made of Polypropylene and can withstand upto   a maximum of 60⁰C.
                            </li>
                            <li>Nozzles are of non-clogging design.  </li>
                            <li>Replacement of nozzle does not demand any downtime.  </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default SprayNozzles