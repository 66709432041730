import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { AfterSalesAndServices } from '../../utilities/sidebarItems';
import AirCooledRebundling from '../../components/AfterSalesAndServices/AirCooledRebundling'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'



function AirCooledRebundlingPage() {
    
    var ImageProps = Images.AirCooledRebundling;

    const email = "services@enexio.com";


    const key_facts = {
        title: "KEY FACTS",
        key_points: [
            "Replacement of the heat exchanger bundle",
            "Upgrading of the drive units",
            "Inspection and maintenance",
            "Identification of leaks",
            "Cleaning of the heat exchanger surfaces",
            "Service contracts to maintain availability and performance",
            "Monitoring to enable rapid analysis of defects in the event of malfunctions",
            "The procurement and maintenance of stocks of spare and replacement parts",
        ]
    };


    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={AfterSalesAndServices} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <AirCooledRebundling />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default AirCooledRebundlingPage