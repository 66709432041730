import React from 'react';
import Accordion from '../../commonComponents/Accordion/Accordion';
import { AboutUsitems } from '../../utilities/sidebarItems';
import Sidebar from '../../commonComponents/Sidebar/Sidebar';
import Images from '../../commonComponents/Images/Images';
import Banner from '../../commonComponents/Banner/Banner';
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'




const History = () => {
    var ImageProps = Images.HistoryBanner

    const AccoridanData = [


        {
            id: 1,
            label: 'Accordian13',
            header: '1920: Foundation of GEA in Bochum by Otto Happel Sr. (Born 1882) ',
            content: <><div className='accordian-para'>
                <p>Founded on 2 February 1920, GEA, abbreviated from the original German name of the company “Gesellschaft für Entstaubungs-Anlagen mbH”, was the work of a man who was an entrepreneur with heart and soul, a man gifted with an exceptional technical insight and filled with the desire for pioneering technical innovations.
                </p>
            </div></>

        },
        {
            id: 2,
            label: 'Accordian14',
            header: '1935: The Air Cooled Condenser – a Technical Milestone',
            content: <><div className='accordian-para'>
                <p>In 1935, Otto Happel, in co-operation with the engineer Dr. Kurt Lang, began developing air-cooled condensers for stationary steam turbines.
                </p>
            </div></>
        },
        {
            id: 3,
            label: 'Accordian15',
            header: '1939: Start-up of the first Air Cooled Condenser (ACC)',
            content: <><div className='accordian-para'>
                <p>Start-up of the first air-cooled condenser serving a steam turbine at the Prosper mine in Essen, Germany, transferred to the Wirus site in Gütersloh, Germany, after the second World War; condensers were originally developed for locomotives. </p>
            </div></>
        },
        {
            id: 4,
            label: 'Accordian16',
            header: '1940: Invention of the “Heller – System”',
            content: <><div className='accordian-para'>
                <p>Professor Heller, who actually later founded the company EGI, invented the “Heller System”, an indirect dry cooling solution in 1940.
                </p>
            </div></>
        },
        {
            id: 5,
            label: 'Accordian17',
            header: '1950: Further development in water conserving cooling systems',
            content: <><div className='accordian-para'>
                <p>Starting from the early 1950’s activities in the field of water conserving cooling systems making use of the related patents by Professor Heller’s inventions combined also with Dr. László Forgó’s patents for advanced air coolers were developed – a group of water-to-air compact heat exchangers named Forgó-type.</p>
            </div></>
        },
        {
            id: 6,
            label: 'Accordian18',
            header: '1954: First Pilot plant scale Heller System',
            content: <><div className='accordian-para'>
                <p>The first pilot plant scale Heller System with natural draft concrete tower was built in 1954 in Hungary.
                </p>
            </div></>
        },
        {
            id: 7,
            label: 'Accordian19',
            header: '1956: First ACC for a public utility power station',
            content: <><div className='accordian-para'>
                <p>First air-cooled condensers for a public utility power station in Trasimeno, Italy, rated 2 x 29 and 36 MW.
                </p>
            </div></>
        },
        {
            id: 8,
            label: 'Accordian20',
            header: '1962: First Heller-System for a large scale coal-fired power unit',
            content: <><div className='accordian-para'>
                <p>The first Heller System for a large scale coal fired power unit outside Hungary:  120 MW Rugeley A Power Station, United Kingdom, 1962 (decommissioned in 1994).</p>
            </div></>
        },
        {
            id: 9,
            label: 'Accordian21',
            header: '1965: Our first natural-draft cooling tower built',
            content: <><div className='accordian-para'>
                <p>First natural-draft cooling tower rated 300 MW erected under license at the Niederaussem power station, Germany.
                </p>
            </div></>
        },
        {
            id: 10,
            label: 'Accordian22',
            header: '1970s: Projects in Water and Wastewater treatment',
            content: <><div className='accordian-para'>
                <p>Since 1974 we serve the water and wastewater industry with 2H components and solutions.
                </p>
            </div></>
        },
        {
            id: 11,
            label: 'Accordian23',
            header: '1989: The first hybrid cooling tower',
            content: <><div className='accordian-para'>
                <p>The first hybrid cooling tower for Stadtwerke Frankfurt 1989.
                </p>
            </div></>
        },
        {
            id: 12,
            label: 'Accordian24',
            header: '1992: Acquisition of EGI Contracting/Engineering Rt.',
            content: <><div className='accordian-para'>
                <p>GEA took the opportunity in 1992 to purchase “EGI Contracting/Engineering Rt.” In Budapest, Hungary (dry cooling at power stations).
                </p>
            </div></>
        },
        {
            id: 13,
            label: 'Accordian25',
            header: '1994: Conversion of a mechanical draft cooling tower',
            content: <><div className='accordian-para'>
                <p>Conversion of a mechanical draft cooling tower into a hybrid cooling tower in the power plant Killingholm, U.K. in 1994.</p>
            </div></>
        },
        {
            id: 14,
            label: 'Accordian26',
            header: '1997: Reorganization of the Group',
            content: <><div className='accordian-para'>
                <p>After the dynamic growth in the early 1990 years and because of the world wide recession,  the years after 1995 showed a phase of integration and consolidation in order to “digest “ the new activities. In 1997 GEA Group is structured in nine Divisions.
                </p>
            </div></>
        },
        {
            id: 15,
            label: 'Accordian27',
            header: '1999: MG / GEA - the takeover',
            content: <><div className='accordian-para'>
                <p>In April 1999, GEA was acquired by mg technologies AG (the successor of Metallgesellschaft).  </p>
            </div></>
        },
        {
            id: 16,
            label: 'Accordian28',
            header: '2005: Acquisition of 2H Kunststoff',
            content: <><div className='accordian-para'>
                <p>Takeover of  2H Kunststoff, a specialist for cooling tower fills as well as for water and waste-water treatment.
                </p>
            </div></>
        },
        {
            id: 17,
            label: 'Accordian29',
            header: '2006: Acquisition of 2H Aqua',
            content: <><div className='accordian-para'>
                <p>GEA acquired 2H Aqua based in UK.
                </p>
            </div></>
        },
        {
            id: 18,
            label: 'Accordian30',
            header: '2010: Reorganization of the Group',
            content: <><div className='accordian-para'>
                <p>
                    Reorganization of GEA's nine Divisions into technologically distinct Segments. The largest segment is the Heat Exchangers Segment (HX) including the business units Wet Cooling Towers and ACC/ Heller.
                </p>
            </div></>
        },
        {
            id: 19,
            label: 'Accordian31',
            header: '2013: GEA announced to divest HX Segment',
            content: <><div className='accordian-para'>
                <p>In June 2014, the board of GEA Group announced their decision to sell the Heat Exchangers Segment (HX). In October, GEA Group concludes the agreement on the sale of the Heat Exchangers Segment to Triton.
                </p>
            </div></>
        },
        {
            id: 20,
            label: 'Accordian32',
            header: '2014: Acquired by Triton - Partners',
            content: <><div className='accordian-para'>
                <p>In November 2014, The Triton funds acquired the entire Heat Exchangers Segment.
                </p>
            </div></>
        },
        {
            id: 21,
            label: 'Accordian33',
            header: '2015: Brand change and standalone presence of power cooling solutions: ENEXIO',
            content: <><div className='accordian-para'>
                <p>
                    Due to change of ownership, another new standalone company has been created out of the former Heat Exchanger segment of the GEA Group AG. The Power Cooling Solutions division continues separately and independently under the new name ENEXIO. The other segments of the former GEA Heat Exchanger Group operate under different names and market presence, like DencoHappel (former Air Treatment) and Kelvion (former Heat Exchanger Systems).
                </p>
            </div></>
        },
        {
            id: 22,
            label: 'Accordian34',
            header: '2017: ENEXIO won German Brand Award 2017',
            content: <><div className='accordian-para'>
                <p>With the support of the new owner, former GEA Energietechnik and its subsidiary companies became ENEXIO. Not only does the name connect the company’s core values of “Energy, Engineering, Excellence”, the whole new corporate presence is meant to mirror the design of the company. Development included various internal and external marketing and communication actions for the brand formation of ENEXIO – accompanied by a new website, an active and new social media presence, a new trade fair and advertising concept as well as internal communication activities – followed an intense integration process in the build-up phase.
                </p>
            </div></>
        },
        {
            id: 23,
            label: 'Accordian35',
            header: '2023: ENEXIO ACC GmbH acquired ENEXIO MANAGEMENT GmbH',
            content: <><div className='accordian-para'>
                <p>ENEXIO MANAGEMENT GmbH acquired by ENEXIO ACC GmbH and controls worldwide operations.


                </p>
            </div></>
        },


    ]
    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={AboutUsitems} />
                </div>

                <div className="col-lg-9 col-lg-9 col-sm-12 main-content aboutus-sections-sub">
                    <div id="c3677">
                        <h1 className="csc-firstHeader mb-3">History</h1>
                    </div>
                    <div>
                        <h2 className='ice-blue'>From being the inventor of the first Air-cooled condenser in 1935 to German Brand Award in 2017</h2>
                        <p>
                            <span className="lead">
                                More than 80 years in the market and a great deal of success have made us a leading provider worldwide in developing and manufacturing both wet and dry cooling systems. We have a long history delivering beneficial industry-first technology: We developed the first Air Cooled Condenser in 1935, we are the inventor of the Heller-Technology in 1940 and our first natural draft wet cooling tower was erected in 1965. Since 1974 we serve the water and wastewater industry with 2H components and solutions and revolutionized the waste management in coal-fired stations with our patented Circumix technology in 1990.
                            </span>
                        </p>
                    </div>
                    <div>
                        <h3>MILESTONES</h3>
                    </div>
                    <div>
                        <Accordion AccoridanDataProps={AccoridanData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History