import React from 'react'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { AfterSalesAndServices } from '../../utilities/sidebarItems';
import AfterSalesandServices from '../../components/AfterSalesAndServices/AfterSalesandServices'
import Images from '../../commonComponents/Images/Images'
import Banner from '../../commonComponents/Banner/Banner'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'




function AfterSalesandServicesPage() {
    var ImageProps = Images.serviceBanner

    const email = "services@enexio.com";


    return (
        <>
            <div>
                <Banner ImageProps={ImageProps} />

                <AppBreadcrumbs />
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12'>
                        <Sidebar sidebarItems={AfterSalesAndServices} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <AfterSalesandServices />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12'>
                        <ContactSidebar showDownloads={false} showContactInfo={true} email={email} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default AfterSalesandServicesPage