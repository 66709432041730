import React from 'react'
import "../../assets/css/CorporateGovernance.css"

function CorporateGovernanc() {

    return (
        <div className='corporate-main corporate-governance'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>Corporate Governance</h1>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>Tone from the top</h2>
                </div>
                <div className='headtext-section'>
                    <article>
                        <p>
                            <span className="lead">
                                For us at ENEXIO, integrity means acting in accordance with our
                                values – excellence, responsibility, integrity, passion, and
                                diversity – wherever we do business. A key element of integrity
                                is compliance: adherence to the law and to our own internal
                                regulations. We have zero tolerance for corruption and violations
                                of the principles of fair competition – and where these do occur,
                                we respond rigorously.
                            </span>{' '}
                        </p>
                    </article>
                </div>
                <div id="c1437" className='headtext-section'>
                    <p>
                        The ENEXIO Management Board sets the following principles by way of a
                        Tone from the Top and provides, by all Compliance policies and
                        guidelines, the framework for an ethical business conduct of all
                        officers and employees.
                    </p>
                </div>
                <div id="c1438" className='heading-text corporate-governance'>
                    <ul>
                        <li>
                            Compliance with all legal regulations and internal company policies
                            is a key management duty at ENEXIO and has absolute priority, even
                            if it has a negative impact on the business (whether internal or
                            external financial targets or other business goals).
                            <br />
                            <br />

                        </li>

                        <li>
                            ENEXIO expects legally and ethically impeccable conduct from all of
                            its directors and employees in daily business operations because
                            the way they carry out their duties affects the company’s reputation
                            and economic viability.
                            <br />
                            <br />
                        </li>
                        <li>
                            The Management Board declares that ENEXIO is unreservedly committed
                            to Compliance and will forgo any business transactions that would
                            violate any compliance principles.
                            <br />
                            <br />
                        </li>
                        <li>
                            The ENEXIO Global Code of Conduct in conjunction with the
                            Anticorruption Policy, the Antitrust Policy, the Export Control
                            Policy, and further policies, detail the organizational framework
                            for corporate compliance and specify areas in which violations of
                            applicable law can have particularly serious adverse consequences,
                            both for the entire enterprise and for individual employees.
                            <br />
                            <br />
                        </li>
                        <li>
                            The principles set forth in these policies are designed to guide
                            directors and employees in their business-related actions and
                            protect them from misconduct.
                            <br />
                            <br />
                        </li>
                        <li>
                            The ENEXIO Compliance Organisation provides support for this
                            purpose. Employees may contact their respective supervisors or
                            Compliance Officers for support and advice on ensuring legally
                            compliant conduct in specific business situations.
                            <br />
                            <br />
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default CorporateGovernanc