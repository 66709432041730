import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'
import ClassicAirCooledCondenser from '../../components/CoolingSolutions/ClassicAirCooledCondenser'

function ClassicAirCooledCondensersPage() {
    var ImageProps = Images.classic_acc

    const email = "sales@enexio.com";

    const key_facts = {
        title: "KEY FACTS",
        key_points: [
            "Fast, easy and safe erection at ground level",
            "Tight ACC plant",
            "Less pressure lost",
            "Reduced foundation",
            "Safe structure",
            "Dynamic calculation",
        ]
    };

    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <ClassicAirCooledCondenser />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default ClassicAirCooledCondensersPage