import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"


const WccAcc = () => {

    return (
        <div className='wcc-acc-main'>
            <div className='heading-text mb-3'>
                <h1>Conversion of WCC - ACC</h1>
            </div>
            <div id="c1437" className='headtext-section'>
                <p >As the world is progressing ahead in terms of economy and growth. Nations have realised the importance of moving ahead in a sustainable manner. Various international organisations like UNICEF have concluded that there must be a paradigm shift in the utilisation of energy. Many cities all over the world are dealing with a combination of water and energy issues. Surprisingly, few people are aware that the two resources are interlinked. Without power, you cannot have adequate water and vice versa. Energy generation requires huge amount of water. ENEXIO greatly creates sustainability by being the inventor of Air-Cooled Condenser , which frees up and saves close to 3 times of the water volumes as the Power Plant capacity and water scare areas Power generation companies are shifting to this mode of conversion of water-cooled condenser to air cooled condenser. ENEXIO helps you in the conversion as our specialist have the expertise and experience to assist for your technology shift from WCC to ACC.   </p>
            </div>
            <div id="c1438" className='headtext-section'>
                <p>  <span className='lead'>Why WCC to ACC ? </span></p>
                <p>  <span className='lead'>Water Scarcity </span></p>
                <p> A cooling tower implies abundance of water requirement. Air Cooled Condenser do not require water and as river source and ground water depletes and is inconsistent year on year, plants are forced to convert WCC to ACC.
                </p>

                <p>  <span className='lead'>Low Maintenance </span></p>
                <p> Air-Cooled condensers have less utility power consumption compared to cooling towers. ACC’s have fewer components and a simpler process flow compared to water-cooled condensers, which involves complex systems like cooling water, drives, blow down, and treatment, leading to higher maintenance needs. Additionally, Air-Cooled condensers eliminate the cost associated with chemical treatment of condenser water, reducing. </p>

                <p>  <span className='lead'>Sustainability drive </span></p>
                <p> As the world drives into a sustainability drive to preserve earths critical resources and climate, ENEXIO ACC supports the process by saving very precious water for better human consumption use than industrial waste. 2% of water consumption per hour is clear savings of water against conventional system.  </p>

                <p>  <span className='lead'>Less cost </span></p>
                <p> Savings in Water replenishment cost, Spare managements, Chemicals cost, and maintenance enable to lower the Operational and maintenance costs. </p>
            </div>

        </div>



    )
}

export default WccAcc