import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import PagesCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"


const IDCT = () => {
    const image1 = Images.wetcoolingsystem2;

    const pageImages = [


        {
            id: 1,
            image: Images.idct1,
            title: "ENEXIO IDCT for 1 x 180 MW Power Plant - Anrak Aluminium in Vizag"
        },
        {
            id: 2,
            image: Images.idct2,
            title: "ENEXIO IDCT for 4 X 135 MW Power Plant - KSK Warora"
        },
        {
            id: 3,
            image: Images.idct3,
            title: "ENEXIO IDCT for 2 X 660 MW Power Plant in NTPC SOLAPUR"
        },
        {
            id: 4,
            image: Images.idct4,
            title: "ENEXIO IDCT for 4 X 350 MW Power Plant - RKM Power, Chattisgarh"
        },
        {
            id: 5,
            image: Images.idct5,
            title: "ENEXIO IDCT for 1 x 80 MW Power Plant - Hindustan Zinc Zawar"
        },
        {
            id: 6,
            image: Images.idct6,
            title: "ENEXIO IDCT for 1 x 330 MW Power Plant - RRVUNL, Dolhpur"
        },


    ]

    return (
        <div className='cooling-solutions IDCT'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>INDUCED DRAFT COOLING TOWER </h1>
                </div>

                <div id="c1437" className='headtext-section'>
                    <p className='lead'>
                        ENEXIO is one of the leading manufacturers of Induced draft cooling towers, catering to Industries and for all applications.   </p>
                    <p>Enexio ensures that the cooling tower is designed as per the client’s requirement, based on a wide range of factors, starting from the circulating water quality to other local meteorological conditions. The cooling towers are optimised based on the foot print area, power consumption & pumping head requirement. </p>
                    <p>The designs are optimised not only with respect to the Capex but with respect to Opex also. We, at Enexio, take utmost care in determining the aspect ratio, the velocities at critical points viz., air inlet, fill & fan stack exit. These parameters ensures that the re-circulation is minimized to a larger extent.   </p>
                    <p>Enexio also follows the recommendations given in the specified literature for citing and orientation, so as to prevent interference in performance from adjacent cooling towers.  Cooling towers are tested in conformity to CTI ATC 105.  </p>
                    <p>We, have different types of fill manufactured depending upon the water quality, from clarified water to seawater. We have in our manufacturing range, modular splash / trickle fill, ideally suited for brackish / seawater applications. Fills are manufactured with high precision moulding machines at our state- art -the art manufacturing facility.</p>
                </div>
                <div className='text-center mb-3  mb-5'>
                    <img src={image1} alt="Rcc2" width="100%" />
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div>
                            <div className='sub-heading'>
                                <h2 className='ice-blue text-center'>RCC TOWERS</h2>
                            </div>

                            <div id="c1438" className='heading-text corporate-governance'>
                                <ul>
                                    <li>
                                        Enexio designs and builds Concrete Cooling towers, using the latest prevailing codes and ensures that the design life of the concrete structure is equivalent to the plant life.
                                        <br/>
                                        <br/>

                                    </li>
                                    <li>
                                        Enexio also ensures that the civil design is optimized with respect to the civil quantities, without compromising on the applicable codes and structural stability.
                                        <br/>

                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div>
                            <div className='sub-heading'>
                                <h2 className='ice-blue text-center'>FRP TOWERS</h2>
                            </div>

                            <div id="c1438" className='heading-text'>
                                <ul>
                                    <li>
                                        Enexio takes precautions to design the main columns of the FRP structural framework to transmit all loads to the cooling tower foundations & shall be braced properly by the horizontal & diagonal members.
                                        <br/>
                                        <br/>

                                    </li>
                                    <li>
                                        We follow CTI STD – 137 & CTI STD – 152 for Pultruded FRP products use in CT & structural design of FRP components respectively. Enexio offers optimal FRP solutions for fresh water & seawater cooling towers, by selecting the right type of resin depending upon the application.
                                        <br/>

                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                </div>

                <div className='mb-4 headtext-section'>
                    <p className='lead'>Enexio’s key references include:</p>
                    <div id="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>CLIENT NAME </th>
                                    <th>TOTAL CAPACITY</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="list-cell">
                                        M/s. RKM Powergen
                                    </td>
                                    <td className="list-cell">
                                        200,000 cu.m/hr.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="list-cell">
                                        M/s. Orissa Power   Generation Company
                                    </td>
                                    <td className="list-cell">
                                        160,000 cu.m/hr
                                    </td>
                                </tr>
                                <tr>
                                    <td className="list-cell">M/s. National Thermal Power Corporation, Solapur</td>
                                    <td className="list-cell">
                                        144,000 cu.m/hr
                                    </td>
                                </tr>
                                <tr>
                                    <td className="list-cell">M/s. Anrak Aluminium </td>
                                    <td className="list-cell">
                                        44,400 cu.m/hr.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='heading-text'>
                    <h3>REFERENCES AROUND THE GLOBE</h3>
                    <PagesCarousel pageImages={pageImages} />
                </div>
            </div>

        </div>
    )
}

export default IDCT