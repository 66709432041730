import React from 'react'
import Accordion from '../../commonComponents/Accordion/Accordion'
import Images from '../../commonComponents/Images/Images'

function OurManagement() {
    const AccordionData = [
        {
            id: 1,
            label: 'Accordian10',
            header: 'CHAIRMAN & MANAGING DIRECTOR',
            content: <> <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

                    <img src={Images.managingDir} />
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <p className='accordion-text'>Ms Swarnamugi Raghupathy is a seasoned business leader and visionary strategist, currently serving as the Chairman of Enexio Power Cooling Solutions India Pvt Ltd, a distinguished company renowned for its excellence in Power Cooling Solutions. An Engineer and a Post Graduate Diploma in embedded systems. With a career spanning 19 years, Ms Swarnamugi brings a wealth of experience and expertise to the helm of the organization. She is also the Founder and Director of BGR NEO LTD a robotic and Automation solutions for varied MNC and other FMCG sectors across India. </p>
                    <p className='accordion-text'>    A stalwart in the industry, Ms Swarnamugi has a proven track record of driving growth, fostering innovation, and delivering value. Her strategic foresight and unwavering commitment to excellence have been instrumental in steering Enexio Power Cooling Solutions India Pvt Ltd through dynamic market landscapes and positioning it as a formidable player in the global marketplace. </p>
                    <p className='accordion-text'>Prior to assuming the role of Chairman, Ms Swarnamugi held key leadership position as Managing director within Enexio Power Cooling Solutions India Pvt Ltd and other esteemed organizations, where she demonstrated exceptional leadership skills and a keen understanding of market dynamics. Her leadership style, characterized by integrity, transparency, and a people-centric approach, has earned them the respect and admiration of colleagues and industry peers alike. </p>
                    {/* <p className='accordion-text'>
                        Under Ms Swarnamugi’s stewardship, Enexio Power Cooling Solutions India Pvt Ltd has achieved numerous milestones. Her strategic initiatives have propelled the company to new heights of success, cementing its reputation as an industry leader and driving sustainable growth in the face of evolving challenges. </p>
                    <p className='accordion-text'>
                        Beyond her professional achievements, Ms Swarnamugi is actively involved in philanthropic endeavours and corporate social responsibility initiatives, reflecting their commitment to making a positive impact on society. </p>
                    <p className='accordion-text'>
                        In summary, Ms Swarnamugi epitomizes leadership excellence and inspires confidence in stakeholders through their visionary leadership, strategic acumen, and unwavering dedication to advancing Enexio Power Cooling Solutions India Pvt Ltd.’s mission and values.
                    </p> */}
                </div>
            </div></>
        },
        {
            id: 2,
            label: 'Accordian9',
            header: 'VICE CHAIRMAN ',
            content: <> <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

                    <img src={Images.vcp} />
                </div>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <p className='accordion-text'>
                        Mr. R. Ramesh Kumar, 61 years, has been Director of ENEXIO Power Cooling Solutions India Private Limited (Formerly GEA Cooling Tower Technologies (India) Private Limited) since May 20, 1999. Subsequently, appointed as Vice Chairman on September 23, 2013.

                        He holds a Master’s degree in Public Administration from University of Madras and is a qualified Fellow Company Secretary, an Associate Member of Institute of Chartered Secretaries and Administrators, United Kingdom and holds a diploma in Arbitration. He has over 35 years of rich and proven experience at top leadership level in Corporate governance, Company secretarial, policy formation, Corporate Advisory, Law, Contracts, Strategic management, Foreign Direct Investment, Technology agreements, Overseas direct investments, Accounting, Taxation, Property management, Internal audit, Risk management, credit control, Joint venture and Partnership management.
                    </p>
                </div>
            </div></>
        },
        {
            id: 3,
            label: 'Accordian11',
            header: 'PRESIDENT ',
            content: <>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 p-0 ourmanagementImg'>

                        <img src={Images.presidentImg} />
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12' >
                        <p className='accordion-text'>Mr. T. Parasuram has been President of ENEXIO Power Cooling Solutions India Pvt. Ltd. Since January 2008. He held leading positions in the Manufacturing, Maintenance and Project Management in the AFC division of BGR Energy between 1995 to 2006. Before joining BGR groups he had been in a leading petrochemical company, Tamil Nadu Petro products Ltd. He has been involved in setting up several of the manufacturing units of ENEXIO India & BGR- AFC. Mr. T. Parasuram has a Mechanical Engineering Degree from Manipal Institute of Technology. </p>
                    </div>
                </div>
            </>
        },

    ]

    return (
        <div className='ourmanagement-main'>
            <div className='heading-text'>
                <h1>OUR MANAGEMENT TEAM</h1>
                <h2 className='ice-blue'>Success is a matter of a good team</h2>
                <p><span className="lead">Our management team executes strategies to adapt our solutions and services to the individual needs of our customers and other business partners worldwide:</span></p>
                <ul><li>We act with <strong>integrity</strong> and <strong>professionalism</strong></li><li>We deliver <strong>excellence</strong> around the world through an <strong>integrated global team</strong></li><li>We <strong>innovate</strong> continuously</li><li>We are <strong>align</strong> with <strong>customer needs</strong> and <strong>expectations&nbsp;</strong></li></ul>
            </div>
            <div className='accordian-section'>
                <Accordion AccoridanDataProps={AccordionData} />
            </div>
        </div>
    )
}

export default OurManagement