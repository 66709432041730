import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"


const AirCooledRebundling = () => {

    return (
        <div className='aircooledreundling-main'>
            <div className='heading-text mb-3'>
                <h1>AIR COOLED CONDENSER REBUNDLING</h1>
            </div>
            <div className='sub-heading headtext-section'>
                <h2 className='ice-blue'>IS YOUR ACC COSTING YOU MW? </h2>
            </div>
            <div id="c1437" className='headtext-section'>
                <p className="lead">Extend your power plant life time and boost MW output by increasing Condensing Capacity with ENEXIO ACC Rebundling.  </p>
                <p className="lead">
                    <strong> ENEXIO</strong> – as the inventor of the Air-cooled Condenser – has played a leading role in the evolution of this technology. We are known for our comprehensive expertise in the field of power plant cooling technology and we offer the whole range of services for dry cooling systems.
                </p>

            </div>
            <div id="c1438" className='heading-text'>
                <span className='lead'>Typical bundle deficiencies include</span>
                <ul>
                    <li>
                        Fin separation from frozen tube damage
                    </li>
                    <li>
                        Debris trapped behind support beams
                    </li>
                    <li>
                        Air leaks at tube/header connections
                    </li>
                    <li>
                        Severe debris build up on tube fins from sources such as
                        dust, cottonwood tree seeds, pet coke and flyash
                    </li>
                    <li>
                        Obsolete thermal design of multirow condenser limits turbine output
                    </li>
                    <li>
                        Ruptured/burst tubes left behind freezing winters
                    </li>

                </ul>

            </div>
            <div id="c1437" className='headtext-section'>
                <p>The magnitude of reduced ACC performance is typically unknown and therefore customers are not aware of the potential improvement new tube bundles can provide. ENEXIO is the expert to transparently calculate the impact of ACC bundle deficiency on generation output and the revenue growth potential through rebundling. </p>
                <p>Power plant deficiency can be deeply rooted in Air cooled Condenser performance deterioration like ageing, fouling, and scaling of the ACC tube bundles. Typically, a 1 in Hg change in steam turbine back pressure corresponds to a 3% change in STG MW output.  </p>
            </div>
        </div>



    )
}

export default AirCooledRebundling