import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'



const DryAndWetCooling = () => {
    const image1 = Images.DryWetCooling1;
    const image2 = Images.DryWetCooling2;
    const image3 = Images.DryWetCooling3;
    const image4 = Images.DryWetCooling4;

    const email = "services@enexio.com";

    const key_facts = {
        title: "Our Major Activities",
        key_points1: [
            "Tube bundle replacement and refurbishment",
            "ACC Cell Extension",
            "Optimisation and replacement of the fan drive units and ejector system",
            "Vibration measurement for preventive maintenance",
            "Identification and rectification of leaks",
            "Supply of semi-automatic cleaning system",
            "Inspection and trouble shooting",
            "Consultancy for performance improvement",
            "Other tailor-made solutions",
        ],
        key_points2: [
            "Performance improvement of existing CT’s",
            "Fill and Drift eliminators replacement",
            "Replacement of distribution system",
            "Plant assessment and Inspections",
            "Optimization of performance",
            "Replacement of internals",
            "Drive unit overhauls",
            "Complete overhauls of cooling units",
        ]
    };

    return (
        <div className='dryandwetcooling-main '>
            <div className='row'>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='heading-text mb-3'>
                        <h1>AFTER SALES & SERVICE SOLUTIONS </h1>
                    </div>
                    <div>
                        <p className='lead'>Maintenance, Service and Optimisation of Dry and Wet Cooling Plant </p>
                    </div>
                    <div className='headtext-section'>
                        <p className="lead">ENEXIO is the trusted partner from original replacement parts to complex turnkey retrofit projects to achieve maximum plant performance within the shortest shutdown time and minimal expenditures. With our experience over several decades we can offer you service plans tailored to your requirements for performance improvement and operational reliability. </p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} />
                </div>
            </div>
            {/* //Dry cooling services */}
            <div className='row  csc-space-before-20'>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div >
                        <div>
                            <div className='csc-space-before-20 csc-space-after-20 headtext-section'>
                                <h4 className='ice-blue'>DRY COOLING SERVICE SOLUTIONS </h4>
                            </div>
                            <div className='list-nd-image'>
                                <div className=''>
                                    <div id="c1438" className='heading-text corporate-governance'>
                                        <ul>
                                            <li>
                                                Deficiency in power plant can be rooted in ACC performance deterioration like ageing, fouling and scaling of ACC tube bundles. Hence Replacement of heat exchanger bundle is vital to any retrofit project. Through our own manufacturing facility, we can supply tube bundles to any destination, worldwide.
                                            </li>
                                            <li>
                                                Our site specialist asses, reviews and proposes required modification work and completes the work in accordance with set standards.
                                            </li>
                                            <li>ENEXIO Power Cooling Solutions delivers all-inclusive service ensuring same quality as for a new build.</li>

                                        </ul>
                                    </div>
                                </div>
                                <div className='images-div'>
                                    <img className='csc-space-before-20' src={image1} alt="DryWetCooling1" width="100%" />
                                    <img className='csc-space-before-20' src={image2} alt="DryWetCooling2" width="100%" />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='csc-space-before-20 csc-space-after-20 headtext-section'>
                        <h4 className='ice-blue'>SPARE SUPPLIES FOR ANY MAKE</h4>
                        <div id="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='' colSpan="2">DRY COOLING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            ALEX tube bundle
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Bearing
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Fin cleaning

                                            system
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Rotameter
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Fan Assembly
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Coupling
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Gear box
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Motors
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Rupture Disk
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Oil seal etc..
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='sidebar contact-sidebar csc-space-after-20 headtext-section mb-3'>
                        <div id="c1435" className="well well-sm">
                            <h4 className="csc-firstHeader">{key_facts.title}</h4>
                            <div className="img-center img-above">
                                <div className='key-facts'>
                                    {key_facts.key_points1 && key_facts.key_points1.length > 0 ? (
                                        <ul className='key-points-marker'>
                                            {key_facts.key_points1.map((point, index) => (
                                                <li key={index}>{point}</li>
                                            ))}
                                        </ul>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* //wet cooling services */}
            <div className='row'>
                <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='csc-space-before-20 csc-space-after-20'>
                        <div>
                            <div className='csc-space-before-20 csc-space-after-20 headtext-section'>
                                <h4 className='ice-blue'>WET COOLING SERVICE SOLUTIONS</h4>
                            </div>
                            <div className='list-nd-image'>

                                <div className=''>
                                    <div id="c1438" className='heading-text corporate-governance'>
                                        <ul>
                                            <li>
                                                When modifying a plant to improve performance or to replace cooling fills we employ ENEXIO fills manufactured at our state of the art facility located in India which are proven in many of the biggest and most advanced cooling installations in the world, ensuring that we constantly retain the desired characteristics and performance values for the longevity and reliability of the asset.
                                            </li>
                                            <li>
                                                The inspection of crucial components and regular servicing are imperative for the overall efficiency of the plant and we execute this by providing scheduled service to your plant.
                                            </li>
                                            <li>Our specialist provides proper data analysis from the information gathered at your plant leading to maximum availability, operational reliability and plant efficiency, ensuring low TCO (Total cost of ownership).</li>

                                        </ul>
                                    </div>
                                </div>
                                <div className='images-div'>
                                    <img className='csc-space-before-20' src={image3} alt="DryWetCooling1" width="100%" />
                                    <img className='csc-space-before-20' src={image4} alt="DryWetCooling2" width="100%" />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-12 '>
                    <div className='csc-space-before-20 csc-space-after-20 headtext-section'>
                        <h4 className='ice-blue'>SPARE SUPPLIES FOR ANY MAKE</h4>
                        <div id="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='' colSpan="2">WET COOLING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Fills
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Spray Nozzles
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Drift eliminator
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Fan stack
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Gear Box
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Motors
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Fan assembly
                                        </td>
                                        <td className="list-cell">
                                            <span className="list-mark">&#8226;</span>
                                            Drive shaft
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='sidebar contact-sidebar csc-space-after-20 headtext-section mb-3'>
                        <div id="c1435" className="well well-sm">
                            <h4 className="csc-firstHeader">{key_facts.title}</h4>
                            <div className="img-center img-above">
                                <div className='key-facts'>
                                    {key_facts.key_points2 && key_facts.key_points2.length > 0 ? (
                                        <ul className='key-points-marker'>
                                            {key_facts.key_points2.map((point, index) => (
                                                <li key={index}>{point}</li>
                                            ))}
                                        </ul>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DryAndWetCooling