import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import { Link } from 'react-router-dom';



const AirCooledCondensers = () => {
    const image1 = Images.AirCooled;
    const image2 = Images.AirCooledImg1;
    const image3 = Images.AirCooledImg2;


    return (
        <div className='aircooled-main'>
            <div className='heading-text mb-3'>
                <h1>AIR COOLED CONDENSERS (ACC) </h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>ENEXIO IS THE INVENTOR OF THE AIR COOLED CONDENSER</h2>
            </div>
            <div className='headtext-section'>
                <p className="lead"><strong>ENEXIO </strong> -  as the inventor of the Air Cooled Condenser - has played a leading role in the evolution of this dry cooling technology. We are known for our comprehensive expertise in the field of cooling technology for the power generating industry and we offer the whole range of services for dry cooling systems. </p>


            </div>
            <div className='csc-space-before-20 csc-space-after-20 headtext-section'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <img src={image1} alt="airCooledHistory" width="100%" />
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <p >In 1935, Otto Happel, in co-operation with the engineer Dr. Kurt Lang, began developing Air Cooled Condensers for stationary steam turbines. The start-up of the first ACC serving a steam turbine at the Prosper mine in Essen, Germany was in 1939. </p>
                    </div>

                </div>
            </div>
            <div id='c774' className='headtext-section'>

                <p>    <strong>A PROUD PART OF OUR HERITAGE IS BEING THE EXPERTS FOR AIR COOLED CONDENSERS</strong></p>
                <p>
                    The leading market position of ENEXIO in the field of power plant cooling is partly due to its expertise in both cooling processes: Wet Cooling Towers and Dry Cooling Solutions. It is a well-known fact that experience pays off. For decades, ENEXIO has been building cooling systems all over the world, covering everything - from the construction of new systems to the enhancement, maintenance and optimisation of existing systems - always to the highest quality standards and incorporating the latest findings from its own research and development programmes.
                </p>

            </div>
            <div id='c777' class="csc-space-before-20">
                <h3 className='text-center mb-4'> TYPES OF ACC DESIGNS</h3>
            </div>
            <div id='c1448'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div id='c1449' className='mb-2'>
                            <h4 className='text-center csc-firstHeader frst-typ'>Classic ACC</h4>
                            <div className='img-center-inner'>
                                <Link to="/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/">  <img src={image2} alt="AirCooledImg1" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div id='c1449' className='mb-2'>
                            <h4 className='text-center csc-firstHeader scnd-typ'>InAIR</h4>
                            <div className='img-center-inner'>
                                <Link to="/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/">    <img src={image3} alt="AirCooledImg2" /></Link>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AirCooledCondensers