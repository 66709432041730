import React, { useEffect } from 'react';
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems'
import InAir from '../../components/CoolingSolutions/InAir'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'



function InAirPage() {
    var ImageProps = Images.inair

    const key_facts = {
        title: "KEY FACTS",

        image: <img src={Images.inairLogo} alt="" width={"100%"} />,
        key_points: [

            "Less crane operation",
            "Reduced steel structure weight",
            "Reduced length of ESD",
            "Shorter delivery time",
            "Less vibration stress",
            "Less visual impact",
            "Patented around the world",
        ]
    };

    const email = "sales@enexio.com";


    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <InAir />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default InAirPage