import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Sidebar({ sidebarItems }) {
    const location = useLocation();
    const [openSection, setOpenSection] = useState(0);




    useEffect(() => {
        const currentIndex = sidebarItems.findIndex(item => location.pathname.startsWith(item.path));
        // console.log('path', location.pathname.startsWith(item.path));
        // console.log('sidebar', sidebarItems, currentIndex);
        setOpenSection(currentIndex);
    }, [location.pathname, sidebarItems]);
    // console.log("mmm", sidebarItems)


    const handleSectionClick = (index) => {
        // console.log(index, "index;;;;;;;;;;;;;;;;;;;;;;;;;;;;;")
        setOpenSection((prevOpenSection) => (
            prevOpenSection === index ? null : index
        ));
    };


    return (
        <div className="sidebar-main aboutus-sections-sub">
            <ul className="list-group nav nav-list level-1 " id="sidebar-nav">
                {sidebarItems.map((item, index) => (
                    <>
                        {/* {console.log(index, "..............................")} */}
                        <li
                            key={index}
                            className={`list-group-item sidbr tab-2-type ${openSection === index ? 'open-section' : ''}`}
                        >
                            <Link
                                to={item.path}
                                className={`namelinkh accordion-header list-group-item sidbr ${location.pathname.startsWith(item.path) ? 'active-link' : ''}`}
                                onClick={() => handleSectionClick(index)}
                                data-toggle="collapse"
                                data-target={`#level-2-${index}`}
                            >
                                {item.label}
                            </Link>

                            {item.subItems && (
                                <ul className={`list-group accordion-body ${openSection === index ? 'show' : 'collapse'}`} id={`level-2-${index}`}>
                                    {item.subItems.map((subItem, subIndex) => (
                                        <li
                                            key={subIndex}
                                            // className={`list-group-item sidbr sub-menus  ${location.pathname === subItem.path ? 'active-current' : ''}`}

                                            className={`list-group-item sidbr sub-menus ${location.pathname === subItem.path ? 'active-current' : ''}`}
                                            style={subItem.isSpacer ? { paddingLeft: '7px' } : {}
                                            }
                                        >
                                            <Link className='list-group-item sidbr p-0' to={subItem.path}>{subItem.label}</Link>

                                            {subItem.subItems && (
                                                <ul className={`list-group accordion-body ${openSection === index ? 'show' : 'collapse'}`} id={`level-3-${index}-${subIndex}`}>

                                                    {subItem.subItems.map((thirdLevelSubItem, thirdLevelSubIndex) => (
                                                        <li
                                                            key={thirdLevelSubIndex}
                                                            className={`list-group-item sidbr sub-menus  ${location.pathname === thirdLevelSubItem.path ? 'active-current' : ''}`}
                                                        >
                                                            <Link className='list-group-item sidbr p-0' to={thirdLevelSubItem.path}>{thirdLevelSubItem.label}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}

                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    </>
                ))}
            </ul>
        </div>
    );
}

export default Sidebar