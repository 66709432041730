import React from 'react'
import { Link } from 'react-router-dom';
import "../../assets/css/CorporateGovernance.css"

const Vacancies = () => {


    return (
        <div className="main-content aboutus-sections-sub Vacancies-main">
            <div id="c3728">
                <h1 className="csc-firstHeader">Vacancies</h1>
            </div>
            <div id="c3729">
                <h2 className='ice-blue'>Performance. Qualified. Customer oriented.</h2>
                <p>
                    <span className="lead">
                        Do you want to become an integrated part of a global active team? Do not hesitate and have a look at our job offers from below or send us your speculative application via E-Mail to{' '}
                        <Link className='insoenxio' to="mailto:hr@enexioindia.com">hr@enexioindia.com</Link>.
                    </span>
                </p>
            </div>
            {/* <div>
                <iframe src="https://enexio.softgarden.io/widgets/jobs" height="500" width="100%" frameBorder="0"></iframe>
            </div> */}
        </div>
    )
}

export default Vacancies