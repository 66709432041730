import React from 'react'
import Header from '../commonComponents/Header/Header'
import Footer from '../commonComponents/Footer/Footer'
import ComingSoon from '../commonComponents/ComingSoon/ComingSoon'

function ComingSoonLayout() {
    return (
        <div>
            <Header />
            <div className='container'>

                <div>
                    <ComingSoon />
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default ComingSoonLayout