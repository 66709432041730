import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import Images from '../../commonComponents/Images/Images'
import Reference from '../../components/Reference/Reference'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'


const ReferencePage = () => {
    var ImageProps = Images.referencesBanner
    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div>
                <Reference />
            </div>
        </div>
    )
}

export default ReferencePage