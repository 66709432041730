import React from 'react'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { AfterSalesAndServices } from '../../utilities/sidebarItems';
import CombinedDryAndWet from '../../components/AfterSalesAndServices/CombinedDryAndWet'
import Banner from '../../commonComponents/Banner/Banner'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'



function CombinedDryAndWetPage() {
    var ImageProps = Images.serviceBanner

    const email = "services@enexio.com";


    const key_facts = {
        title: "ADVANTAGES",
        key_points: [
            "Reduced water consumption comparing to 100% Wet Cooling System",
            "No plume formation in winter because dry cooling only is possible",
            "Better energy generation in the warm seasons comparing to dry cooling",
            "Smaller investments comparing to dry cooling",
            "Smaller footprint comparing to dry cooling",
            "Reduced power consumption for fan operation comparing to dry cooling",
        ]
    };

    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={AfterSalesAndServices} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <CombinedDryAndWet />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default CombinedDryAndWetPage