import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import IDCT from '../../components/CoolingSolutions/IDCT'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'


function IDCTPage() {
    var ImageProps = Images.IDCTbanner

    const email = "sales@enexio.com";

    const key_facts = {
        title: "ADVANTAGES",
        key_points: [
            "Size of sprinkled area for each cell: 10 m² to 370 m²",
            "Modular fibreglass structure",
            "Modular steel structure",
            "Complete project management",
            "Superior product quality in accordance with our stringent standards",
            "Implementation of countryspecific standards",
            "Great delivery reliability",
            "State of the art cooling tower fills",
        ]
    };


    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <IDCT />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default IDCTPage