import React from 'react'
import { Link } from 'react-router-dom'
import "../../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../../commonComponents/Images/Images'


const ModularSplashFill = () => {
    const image2 = Images.NF;
    const image4 = Images.Drift;
    const image6 = Images.FilmFill;
    const image8 = Images.SprayNozzle;


    return (
        <div className='cooling-solutions ctcomponents'>
            <div className="main-content">
                <div className='heading-text mb-3'>
                    <h1>NF 20/NET 150 MODULAR SPLASH FILL</h1>
                </div>
                {/* <div className='sub-heading'>
                    <h2 className=''>COOLING TOWER COMPONENTS</h2>
                </div>
                <div className='sub-heading'>
                    <h2 className='ice-blue'>COMPONENTS AND FILLS FOR EVAPORATIVE COOLING TOWERS</h2>
                </div>
                <div className='headtext-section'>
                    <p className="lead">Evaporative cooling towers are still considered the most efficient way of cooling process water at industrial sites all over the world. With economic and ecological factors always an important consideration, construction and operation of wet cooling towers necessitates the use of efficient fills and drift eliminators. As the pioneer of plastic components for cooling tower applications we help our customers to meet their requirements.</p>
                </div> */}

                <div className='mb-4'>
                    {/* <div className='sub-heading'>
                        <h2 className='ice-blue'>NF 20/NET 150 MODULAR SPLASH FILL</h2>
                    </div> */}
                    <div className='text-center mb-3'>
                        {/* <img src={Images.NF} alt="RccTowers" width="100%" /> */}
                    </div>
                    <div id="c1438" className='heading-text corporate-governance'>
                        <ul>
                            <li>
                                NF 20 / NET 150 (Next Gen modular splash fill) is a state-of-art innovation in splash cooling technology.
                            </li>
                            <li>
                                Water droplets fall from web to web and offer a self-cleaning characteristic thereby ideally suited for brackish / seawater & dirty water applications.
                            </li>
                            <li>It increases the residence time distribution of air and water droplets. </li>
                            <li>Due to the fact that this fill is of splash type, the droplets in the air between the webs give additional exchange surface thereby enhancing  overall heat & mass transfer capability of the fill. </li>
                            <li>These fills are freely rested and supported on concrete / FRP beams thereby eliminates the cumbersome stainless steel wires and accessories, which are required for supporting the conventional splash bars / grids. </li>
                            <li>Excellent temperature gradient in terms of heat and mass transfer with efficient cooling resulting in relatively lower pressure drop across the fill zone as well as the static pumping head.</li>
                        </ul>

                        <div id="table-container ct-components">
                            <table>
                                <thead>
                                    <tr>
                                        <th>MAXIMUM DIMENSIONS<br />
                                            (L X W x H)
                                        </th>
                                        <th>MATERIAL </th>
                                        <th>GEOMETRIC SURFACE AREA

                                        </th>
                                        <th>EFFECTIVE HEAT TRANSFER AREA<br />

                                        </th>
                                        <th>CORRUGATION HEIGHT
                                        </th>
                                        <th>CONTINUOUS OPERATING TEMPERATURE
                                        </th>
                                        <th>MAXIMUM OPERATING TEMPERATURE <br />(Short Time)
                                        </th>
                                    </tr>
                                    <tr className='table-units'>
                                        <th>mm </th>
                                        <th> </th>
                                        <th> m2/m3 </th>
                                        <th>  m2/m3 </th>
                                        <th>  mm </th>
                                        <th> ⁰C </th>
                                        <th>   ⁰C </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="list-cell text-center">
                                            908 x 604 x 450
                                        </td>
                                        <td className="list-cell text-center">
                                            PP
                                        </td>
                                        <td className="list-cell text-center">450</td>
                                        <td className="list-cell text-center">128 - 130</td>
                                        <td className="list-cell text-center">20 </td>
                                        <td className="list-cell text-center">80  </td>
                                        <td className="list-cell text-center">90 </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>

        </div >
    )
}

export default ModularSplashFill