import React from 'react'

function Accordion({ AccoridanDataProps }) {

    return (
        <div className='accordion-main'>
            <div className="accordion" id="accordionExample">
                {AccoridanDataProps.map((data, index) => (
                    <div className={`accordion-item ${index === 0 ? 'show' : ''}`} key={index} >
                        <h4 className="accordion-header" id="headingOne">
                            <button className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${data.label}`} aria-expanded={index === 0 ? 'true' : 'false'}
                                aria-controls={`collapse${index + 1}`}>
                                {data.header}
                            </button>
                        </h4>
                        <div id={data.label} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                            aria-labelledby={`heading${index + 1}`} data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {data.content}
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default Accordion