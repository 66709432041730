import React from 'react'
import "../../assets/css/CoolingSolutions&SalesServices.css"
import Images from '../../commonComponents/Images/Images'
import Banner from '../../commonComponents/Banner/Banner'
import { Link } from 'react-router-dom';



const WetCoolingTowers = () => {

    const image1 = Images.WetCoolingTowers;

    return (
        <div className='wetcoolingtowers-main'>
            <div className='heading-text mb-3'>
                <h1>WET COOLING TOWERS</h1>
            </div>
            <div className='sub-heading'>
                <h2 className='ice-blue'>  EFFICIENT COOLING TOWER TECHNOLOGY FOR HIGH-PERFORMANCE SOLUTIONS </h2>
            </div>
            <div id="c1437" className='headtext-section'>
                <p className='lead'>
                    We design, manufacture, and service cooling towers for the energy and processing industries. With close to three decades of experience and successful installations have made us a major global player in planning and implementation of wet cooling towers. Our Competence Center for wet cooling towers can offer cooling tower design of counter-flow solutions, with an extensive variety of technologies and concepts. The wide range of ENEXIO cooling tower designs ensures all cost and environmental requirements, and minimize operational and service costs.  </p>
            </div>
            <div className='mb-3'>
                <img src={image1} alt="" width={"100%"} />
            </div>
            <div id="c1437" className='headtext-section'>
                <h3 className=''>COOLING TOWERS SELECTION </h3>
                <p>We select optimal solutions from extensive possibilities of configuration such as natural draft cooling towers and induced draft cooling towers made of engineered composites or concrete. Standardized-design solutions for power and industrial markets are tailor-made based on the application, ambient condition, etc. We provide solutions for all fields of cooling tower operation, from great cooling capacities to high levels of operating reliability.</p>

            </div>
            <div id="c1438" className='heading-text headtext-section'>
                <ul>
                    <li>
                        <Link to="/cooling-solutions/wet-cooling-towers/NDCT/"> Natural Draft Cooling Towers</Link>
                    </li>
                    <li>
                        <Link to="/cooling-solutions/wet-cooling-towers/IDCT/">  Induced Draft Cooling Towers</Link>
                    </li>
                    <li>
                        <Link to="/cooling-solutions/wet-cooling-towers/cooling-tower-components/">  Cooling Tower Components</Link>
                    </li>
                </ul>

            </div>
            {/* <div className='headtext-section'>
                <h4 className='ice-blue'>EFFICIENT AND RELIABLE COOLING TOWERS </h4>
                <p>Efficiency is without doubt a decisive criterion for you. Especially, in the energy-generating sector and in process cooling, reliability enjoys highest priority. Our wet cooling towers are synonyms for both: Efficiency as well as reliability. We do have the required experience to give our innovative and cost-reducing solutions a maximum of safety at the same time. Prominent customers throughout the world depend on us. </p>
            </div> */}
        </div>

    )
}

export default WetCoolingTowers