import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'
import AirCooledCondensers from '../../components/CoolingSolutions/AirCooledCondensers'

function AirCooledCondensersPage() {
    var ImageProps = Images.ACC

    const email = "sales@enexio.com";



    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <AirCooledCondensers />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email}/>
                </div>
            </div>

        </div>
    )
}

export default AirCooledCondensersPage