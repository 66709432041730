import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'
import HybridCoolingDelugeAcc from '../../components/CoolingSolutions/HybridCoolingDelugeAcc'

function HybridCoolingDelugeAccPage() {
    var ImageProps = Images.hybridAcc

    const key_facts = {
        title: "KEY ADVANTAGES OF DELUGE ACC",
        key_points: [
            "Most efficient dry-cooling solution for high peak ambient temperatures",
            "A dry-cooling solution resistant to adverse ambient conditions such as high wind speeds and hot air recirculation",
            "Reduction of auxiliary power consumption which results in an increase of gross turbine output",
            "Short lead time and minimized construction costs",
            "Smaller footprint compared to all-dry systems",
            "Less water consumption compared to all-wet systems",
        ]
    };


    const email = "sales@enexio.com";



    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <HybridCoolingDelugeAcc />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default HybridCoolingDelugeAccPage