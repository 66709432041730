import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'
import WetCoolingTowers from '../../components/CoolingSolutions/WetCoolingTowers'

function WetCoolingTowersPage() {
    var ImageProps = Images.wcc

    const email = "sales@enexio.com";

    const key_facts = {
        title: "EFFICIENT AND RELIABLE COOLING TOWERS",
        para: "Efficiency is without doubt a decisive criterion for you. Especially, in the energy-generating sector and in process cooling, reliability enjoys highest priority. Our wet cooling towers are synonyms for both: Efficiency as well as reliability. We do have the required experience to give our innovative and cost-reducing solutions a maximum of safety at the same time. Prominent customers throughout the world depend on us.",
    };

    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <WetCoolingTowers />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email} key_facts={key_facts} />
                </div>
            </div>

        </div>
    )
}

export default WetCoolingTowersPage