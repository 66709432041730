
const navigation = [
    {
        label: 'ABOUT US',
        url: '/about-us/who-we-are/',
        children: [
            { label: 'Who we are', url: '/about-us/who-we-are/' },
            { label: 'Our Management', url: '/about-us/our-management/' },
            { label: 'Corporate Governance', url: '/about-us/corporate-governance/' },
            // { label: 'News & Exibitions', url: '/about-us/news-exhibitions/' },
            { label: 'Quality Policy', url: '/about-us/quality-policy/' },
            { label: 'History', url: '/about-us/history/' },

        ],
    },
    {
        label: 'COOLING SOLUTIONS',
        url: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/',
        children: [{
            label: 'Dry Cooling Systems',
            url: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/',
        }, {
            label: 'Wet Cooling Towers',
            url: '/cooling-solutions/wet-cooling-towers',
        },
        ],
    },
    {
        label: 'AFTER SALES & SERVICES ',
        url: '/after-sales-and-services',
        children: [
            { label: 'Dry & Wet Cooling Services', url: '/after-sales-and-services/dry-wet-cooling-services/' },
            { label: 'PAC - Combined Dry-Wet Cooling Systems', url: '/after-sales-and-services/combined-dry-wet-cooling-systems/' },
            { label: 'Conversion Of WCC-ACC', url: '/after-sales-and-services/wcc-acc/' },
            { label: 'Air Cooled Condenser Rebundling', url: '/after-sales-and-services/air-cooled-condenser-rebundling/' },
        ],
    },

    { label: 'REFERENCES', url: '/references' },

    { label: 'CONTACT', url: '/contact' },

    { label: 'VACANCIES ', url: '/vacancies' },
]

export default navigation;