import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../utilities/sidebarItems';
import CtComponents from '../../components/CoolingSolutions/CtComponents'
import BannerCarousel from "../../commonComponents/PagesCarousel/PagesCarousel"
import BannerCarouselNew from '../../commonComponents/BannerCarouselNew/BannerCarouselNew'


function CtComponentsPage() {
    // var ImageProps = Images.ndct

    const BannerImages = [
        {
            id: 1,
            image: Images.CT_Components1
        },
        {
            id: 2,
            image: Images.CT_Components2
        },
        {
            id: 3,
            image: Images.CT_Components3
        },
        {
            id: 4,
            image: Images.CT_Components4
        },

    ]

    return (
        <div>
            {/* <Banner ImageProps={ImageProps} /> */}
            <BannerCarouselNew bannerImages={BannerImages} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <CtComponents />
                </div>

            </div>

        </div>
    )
}

export default CtComponentsPage