import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import Images from '../../commonComponents/Images/Images'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'

import QualityHse from '../../components/Aboutus/QualityHse'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'
import { AboutUsitems } from '../../utilities/sidebarItems';

const QualityHsePage = () => {
  var ImageProps = Images.QualityHseBanner


  const contactInfo = {
    name: 'Ulrich Stadermann',
    title: 'Chief Compliance Officer',
    address: 'Meesmannstraße 103, DE-44807 Bochum, Germany',
    phone: '+49 234 980 1079',
    fax: '+49 234 980 341079',
    email: 'example@example.com',
  };

  // const downloads = [
  //   { link: Images.ENEXIO_QMHSE_Policy, title: 'ENEXIO QHSE Policy', fileSize: '99 KB' },
  // ];

  return (
    <div>
      <Banner ImageProps={ImageProps} />
      <AppBreadcrumbs />
      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-12'>
          <Sidebar sidebarItems={AboutUsitems} />
        </div>
        <div className='col-lg-9 col-md-9 col-sm-12'>
          <QualityHse />
        </div>
        {/* <div className='col-lg-3 col-md-3 col-sm-12'>
          <ContactSidebar showContactInfo={false} downloads={downloads} />
        </div> */}


      </div>

    </div>
  )
}

export default QualityHsePage