import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BannerCarousel from '../bannerCarousel/bannerCarousel';
// import './app.css';


const BannerCarouselNew = ({ bannerImages }) => {
    const sliderRef = useRef(null);

    useEffect(() => {

    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
    };

    return (
        <div className='bannercarousel-main-home mb-5'>
            <Slider {...settings} ref={sliderRef}>
                {bannerImages.map((obj) => (
                    <div key={obj.id}>
                        <img src={obj.image} alt={`Slide ${obj.id}`} className='w-100' />
                    </div>
                ))}
            </Slider>
            {/* <i class="fa fa-angle-left" aria-hidden="true"></i>
            <i class="fa fa-angle-right" aria-hidden="true"></i> */}

        </div>
    );
};

export default BannerCarouselNew;
