import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { AfterSalesAndServices } from '../../utilities/sidebarItems';
import DryAndWetCooling from '../../components/AfterSalesAndServices/DryAndWetCooling'
import SpareSupplies from '../../commonComponents/SpareSupplies/SpareSupplies'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'



function DryAndWetCoolingPage() {
    var ImageProps = Images.serviceBanner

    const email = "services@enexio.com";



    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={AfterSalesAndServices} />
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <DryAndWetCooling />
                </div>
                {/* <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email}/>
                    <SpareSupplies />

                </div> */}
            </div>

        </div>
    )
}

export default DryAndWetCoolingPage