import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../commonComponents/Images/Images'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { AfterSalesAndServices } from '../../utilities/sidebarItems'
import WccAcc from '../../components/AfterSalesAndServices/WccAcc'
import ContactSidebar from '../../commonComponents/ContactSidebar/ContactSidebar'


function WccAccPage() {
    var ImageProps = Images.serviceBanner
  
    const email = "services@enexio.com";


    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={AfterSalesAndServices} />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <WccAcc />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <ContactSidebar showDownloads={false} showContactInfo={true} email={email}/>
                </div>
            </div>

        </div>
    )
}

export default WccAccPage