import React from 'react'
import Banner from '../../commonComponents/Banner/Banner'
import Images from '../../commonComponents/Images/Images'
import Contact from '../../components/Contact/Contact'
import Address from '../../components/Contact/Address/Address'
import AppBreadcrumbs from '../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Sidebar from '../../commonComponents/Sidebar/Sidebar'
import { contactin } from '../../utilities/sidebarItems';

const ContactPage = () => {
  var ImageProps = Images.Header_Contact
  return (
    <div>
      <Banner ImageProps={ImageProps} />
      <AppBreadcrumbs />
      <div className='row'>
        <div className='col-lg-3 col-md-3 col-sm-12'>
          <Sidebar sidebarItems={contactin} />
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <Contact />

        </div>
      </div>
      {/* <Address /> */}
    </div>
  )
}

export default ContactPage