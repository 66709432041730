import React from 'react'
import AppBreadcrumbs from '../../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../../commonComponents/Images/Images'
import Sidebar from '../../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../../utilities/sidebarItems';
import DriftEliminators from '../../../components/CoolingSolutions/CtComponents/DriftEliminators'
import Banner from '../../../commonComponents/Banner/Banner'



function DriftEliminatorsPage() {

    var ImageProps = Images.CT_Components2



    return (
        <div>
            <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <DriftEliminators />
                </div>

            </div>

        </div>
    )
}

export default DriftEliminatorsPage