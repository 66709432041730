import React from 'react'
// import Banner from '../../commonComponents/Banner/Banner'
import AppBreadcrumbs from '../../../commonComponents/AppBreadcrumbs/AppBreadcrumbs'
import Images from '../../../commonComponents/Images/Images'
import Sidebar from '../../../commonComponents/Sidebar/Sidebar'
import { CoolingSolutions } from '../../../utilities/sidebarItems'
import SprayNozzles from '../../../components/CoolingSolutions/CtComponents/SprayNozzles'
import Banner from '../../../commonComponents/Banner/Banner'



function SprayNozzlesPage() {

    var ImageProps = Images.CT_Components4

  

    return (
        <div>
             <Banner ImageProps={ImageProps} />
            <AppBreadcrumbs />
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Sidebar sidebarItems={CoolingSolutions} />
                </div>
                <div className='col-lg-9 col-md-9 col-sm-12'>
                    <SprayNozzles />
                </div>

            </div>

        </div>
    )
}

export default SprayNozzlesPage